const homeVideo = {
  name: 'homeVideo',
  init() {
    const homeVideoContainer = document.querySelector('.home-video-container');

    if (homeVideoContainer) {
      const mainIframe = homeVideoContainer.querySelector('iframe');
      const mainPlayer = new Vimeo.Player(mainIframe);

      const videoPopup = document.querySelector('.home-video-popup');
      const closeButton = videoPopup.querySelector('.close-button');
      const popupIframe = videoPopup.querySelector('iframe');
      const popupPlayer = new Vimeo.Player(popupIframe);

      homeVideoContainer.addEventListener('click', function (e) {
        e.preventDefault();
        videoPopup.classList.add('is-active');

        mainPlayer.pause();
        popupPlayer.setCurrentTime(0);
        popupPlayer.play();
      });

      closeButton.addEventListener('click', function (e) {
        e.preventDefault();
        videoPopup.classList.remove('is-active');

        mainPlayer.play();
        popupPlayer.pause();
      });
    }
  },
};

export default homeVideo;