const teamSlider = {
  name: 'teamSlider',
  init() {
    const $slider = document.querySelector('.team-slider');

    if ($slider) {
      new Flickity($slider, {
        // options
        cellAlign: 'center',
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        prevNextButtons: true,
        pageDots: false
      });
    }

    // setTimeout(function() {
    //   const elem = document.querySelector('.slider-wrapper');
    //   if (elem) {
    //     var flkty = new Flickity(elem, {
    //       // options
    //       cellAlign: 'center',
    //       wrapAround: true,
    //       imagesLoaded: true,
    //       lazyLoad: true,
    //       prevNextButtons: true,
    //       pageDots: false
    //     });
    //   }
    // }, 1000)
  },
};

export default teamSlider;
