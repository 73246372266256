// app.js
import '@/styles/app.scss';

import sayHello from './modules/hello.js';
import cookiePolicy from './modules/cookie-policy.js';
import scrollTo from './modules/scroll-to.js';
import navigation from './modules/navigation.js';
import teamSlider from './modules/team-slider.js';
import homeVideo from './modules/home-video.js';


// App main
const main = async () => {
  if (import.meta.env.DEV) {
    sayHello.init()
  }

  cookiePolicy.init();
  scrollTo.init();
  navigation.init();
  teamSlider.init();
  homeVideo.init();

};

// Execute async function
main().then(() => {
  console.log();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}