const navigation = {
  name: 'navigation',
  init() {
    // Menu Reducer
    const navigation = document.querySelector('.page-header')

    window.addEventListener('scroll', function() {
      const distance = document.getElementById('content-container').getBoundingClientRect().y;

      if (distance < 0 && window.innerWidth > 767) {
        navigation.classList.add('is-reduced')
      } else {
        navigation.classList.remove('is-reduced')
      }
    });

    // Menu Mobile
    const mobileCta = document.querySelector('.mobile-menu-trigger');
    const mobileNavClose = document.querySelector('.close-mobile-nav');
    const mobileNav = document.querySelector('.mobile-nav');

    if (mobileCta) {
      mobileCta.addEventListener('click', (e) => {
        e.preventDefault();
        mobileNav.classList.add('is-active');
      });
    }

    if (mobileNavClose) {
      mobileNavClose.addEventListener('click', (e) => {
        e.preventDefault();
        mobileNav.classList.remove('is-active');
      });
    }
  },
};

export default navigation;
