const cookiePolicy = {
  name: 'cookiePolicy',
  init() {
    const $cookiePolicy = document.querySelector('.cookie-confirmation');

    if ($cookiePolicy) {
      const $triggerAccept = $cookiePolicy.querySelector('.confirmation-button');

      if (localStorage.getItem('cookiePolicyAccepted') !== 'accepted') {
        $cookiePolicy.classList.add('is-active');

        $triggerAccept.addEventListener('click', (e) => {
          e.preventDefault();
          localStorage.setItem('cookiePolicyAccepted', 'accepted');
          $cookiePolicy.classList.remove('is-active');
        });
      }
    }
  },
};

export default cookiePolicy;
