const scrollTo = {
  name: 'scrollTo',
  init() {
    // Smooth Scroll to href component
    const $trigger = document.querySelector('[data-hero-scroll]');
    if ($trigger) {
      $trigger.addEventListener('click', (e) => {
        e.preventDefault();
        const $target = document.querySelector('.container > *:nth-child(2)')
        $target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      });
    }

    // Home Video Container
    const $videoContainer = document.querySelector('.home-video-wrapper');
    if ($videoContainer) {
      window.addEventListener("scroll", () => {
        let scroll = document.body.getBoundingClientRect().top;
        if (screen.height + scroll <= 45) {
          $videoContainer.classList.add('hide');
        } else {
          $videoContainer.classList.remove('hide');
        }
      });

      setTimeout(() => {
        let scroll = document.body.getBoundingClientRect().top;
        if (screen.height + scroll > 45) {
          window.scrollTo({
            top: screen.height - 45,
            behavior: 'smooth',
          })
        }
      }, 110000);
    }
  },
};

export default scrollTo;
